<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center flex-grow-1">
        <h1 class="mr-4">Transactions</h1>
        <MonthSelector
          from="2021-03"
          :to="new Date().toISOString().substr(0, 7)"
          :value="from"
          @change="val => (from = val)"
          class=" mr-1"
        />
        -
        <MonthSelector
          from="2021-03"
          :to="new Date().toISOString().substr(0, 7)"
          :value="to"
          @change="val => (to = val)"
          class="ml-1 mr-3"
        />
        <ul class="nav nav-tabs flex-grow-1">
          <li class="nav-item" v-for="filterKey in ['all', 'assigned', 'unassigned', 'problematic']" :key="filterKey">
            <button
              class="nav-link text-capitalize"
              :class="{ active: filter === filterKey }"
              @click.prevent="filter = filterKey"
            >
              {{ filterKey }}
              <small
                class="badge badge-pill ml-1"
                :class="{
                  'badge-secondary': filterKey === 'all',
                  'badge-success': filterKey === 'assigned',
                  'badge-warning': filterKey === 'unassigned',
                  'badge-danger': filterKey === 'problematic'
                }"
                >{{ transactions ? transactions[filterKey].length : '?' }}</small
              >
            </button>
          </li>
        </ul>
      </div>
      <TransactionsAutoAssign
        v-if="filter === 'unassigned'"
        :transactions="transactions ? transactions['unassigned'] : null"
        class="ml-2"
      />
      <TransactionsImport class="ml-2" />
      <TransactionsExport
        :transactions="transactions ? transactions['all'] : null"
        :from="from"
        :to="to"
        class="ml-2"
      />
    </div>
    <div>
      <TransactionsTable
        :transactions="transactions ? transactions[filter] : null"
        @openAssignmentLayer="openAssignmentLayer"
      />
    </div>
    <TransactionEditor v-if="transactionInEdit" :transaction="transactionInEdit" @close="transactionInEdit = null" />
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import TransactionsAutoAssign from '@/components/Transactions/TransactionsAutoAssign'
import TransactionsImport from '@/components/Transactions/TransactionsImport'
import TransactionsExport from '@/components/Transactions/TransactionsExport'
import TransactionsTable from '@/components/Transactions/TransactionsTable'
import TransactionEditor from '@/components/Transactions/TransactionEditor'
import ParsePurposeString from '@/helpers/ParsePurposeString'
import MonthSelector from '@/components/MonthSelector'
import moment from 'moment'

export default {
  components: {
    TransactionsAutoAssign,
    TransactionsImport,
    TransactionsExport,
    TransactionEditor,
    TransactionsTable,
    MonthSelector
  },
  data() {
    return {
      transactionSubscription: null,
      rawTransactions: null,
      transactionInEdit: null,
      filter: 'all',
      from: new Date().toISOString().substring(0, 7),
      to: new Date().toISOString().substring(0, 7)
    }
  },
  created() {
    this.subscribe()
  },
  beforeDestroy() {
    this.transactionSubscription()
  },
  computed: {
    transactions() {
      let transactions = this.rawTransactions
      if (transactions === null) return null

      transactions = transactions.map(transaction => {
        transaction.parsedPurpose = ParsePurposeString(transaction.purpose)
        return transaction
      })

      const assigned = transactions.filter(t => t.assigned)
      const unassigned = transactions.filter(t => !t.assigned && !t.problematic)
      const problematic = transactions.filter(t => t.problematic)

      return {
        all: transactions,
        assigned,
        unassigned,
        problematic
      }
    },
    sums() {
      let total = 0
      let assigned = 0
      if (this.transactions) {
        this.transactions['all'].forEach(t => {
          total += t.amount
          assigned += t.assignedAmount || 0
        })
      }
      return { total: parseFloat(total.toFixed(2)), assigned: parseFloat(assigned.toFixed(2)) }
    }
  },
  methods: {
    subscribe() {
      if (this.transactionSubscription) this.transactionSubscription()
      this.rawTransactions = null
      this.transactionSubscription = db
        .collection('private/banking/transactions')
        .where('date', '>=', moment(this.from).format('YYYY-MM-DD'))
        .where(
          'date',
          '<=',
          moment(this.to)
            .endOf('month')
            .format('YYYY-MM-DD')
        )
        .orderBy('date', 'desc')
        .onSnapshot(snapshot => {
          this.rawTransactions = _.orderBy(
            snapshot.docs.map(doc => {
              return { ...doc.data(), id: doc.id }
            }),
            ['date', 'importedAt'],
            ['desc', 'desc']
          )
        })
    },
    openAssignmentLayer(transactionId) {
      const transaction = this.transactions['all'].find(transaction => transaction.id === transactionId)
      this.transactionInEdit = transaction
    }
  },
  watch: {
    from() {
      if (this.from > this.to) this.to = this.from
      this.subscribe()
    },
    to() {
      if (this.to < this.from) this.from = this.to
      this.subscribe()
    }
  }
}
</script>
