const ParsePurposeString = purpose => {
  const purposeParts = []
  const matches = []
  ;[...purpose.matchAll(/((?:[sS]|5-).?-? ?([0-9]{3,5}(?![0-9]|\/)))/g)].forEach(match => {
    matches.push({ ...match, type: 'invoiceCollectionId' })
  })
  ;[...purpose.matchAll(/((?:[sS]|5-).?-? ?[0-9]{3,5}(\/))[0-9]{4,6}/g)].forEach(match => {
    matches.push({ ...match, type: 'invoiceId' })
  })
  ;[...purpose.matchAll(/(MSG-ID: [a-zA-Z0-9]{20})/g)].forEach(match => {
    matches.push({ ...match, type: 'sepaFileId' })
  })
  ;[...purpose.matchAll(/(EG-? ?[0-9]{4})/g)].forEach(match => {
    matches.push({ ...match, type: 'creditNote' })
  })
  let lastPosition = 0
  matches.forEach(match => {
    const { index } = match
    const { length } = match[0]
    if (index !== lastPosition) {
      const string = purpose.substr(lastPosition, index - lastPosition)
      purposeParts.push({
        string,
        type: 'text'
      })
      lastPosition = index + string.length
    }
    const string = purpose.substr(index, length)
    const part = {
      string,
      type: match.type
    }
    if (match.type === 'invoiceCollectionId') {
      part.invoiceCollectionId = 'S-' + match[0].match(/([0-9]{3,5})+/g)
    }
    if (match.type === 'invoiceId') {
      part.invoiceCollectionId = 'S-' + match[0].match(/([0-9]{3,5})/g)[0]
      part.invoiceId = part.invoiceCollectionId + '_' + match[0].split('/')[1]
    }
    if (match.type === 'creditNote') {
      part.creditNoteId = 'EG' + match[0].match(/[0-9]{4}/g)
    }
    if (match.type === 'sepaFileId') {
      part.sepaFileId = match[0].substr(8)
    }
    purposeParts.push(part)
    lastPosition = index + string.length
  })
  if (purpose.length > lastPosition) {
    const string = purpose.substr(lastPosition, purpose.length - lastPosition)
    purposeParts.push({
      string,
      type: 'text'
    })
  }
  return purposeParts
}

export default ParsePurposeString
