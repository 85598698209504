const CompareAssignments = (before, after) => {
  const [updatedAssignments, deletedAssignments] = [[], [], []]

  after.forEach(assignment => {
    const beforeAssignment = before.find(a => a.invoiceId === assignment.invoiceId)
    const isNew = typeof beforeAssignment === 'undefined'
    const isUpdated = !isNew && !_.isEqual(assignment, beforeAssignment)

    if (isNew || isUpdated) updatedAssignments.push(assignment)
  })

  before.forEach(assignment => {
    const isDeleted = typeof after.find(a => a.invoiceId === assignment.invoiceId) === 'undefined'

    if (isDeleted) deletedAssignments.push(assignment)
  })

  const changedInvoiceCollectionIds = []
  const changedInvoiceIds = [...updatedAssignments, ...deletedAssignments].map(assignment => {
    const { invoiceId, invoiceCollectionId } = assignment
    if (changedInvoiceCollectionIds.indexOf(invoiceCollectionId) === -1)
      changedInvoiceCollectionIds.push(invoiceCollectionId)
    return invoiceId
  })

  return { updatedAssignments, deletedAssignments, changedInvoiceIds, changedInvoiceCollectionIds }
}

export default CompareAssignments
