<template>
  <Layer @close="$emit('close')" title="Edit Transaction" width="70rem">
    <div class="transaction-edit-layer">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1 class="transaction-edit-layer__name">
            <div class="d-flex align-items-center">
              <span>{{ transaction.name }}</span>
              <small class="d-inline badge badge-pill badge-success mt-0 ml-2" v-if="transaction.assigned">
                Assigned
              </small>
            </div>
            <small>
              <span>Date: {{ transaction.date || '-/-' }}</span>
              <span class="ml-2"> IBAN: {{ transaction.iban || '-/-' }}</span>
              <span class="ml-2"> BIC: {{ transaction.bic || '-/-' }}</span>
              <span class="ml-2"> ID: {{ transaction.id }}</span>
              <span class="ml-2">
                Type:
                <select :value="transaction.type" @change="changeTransactionType">
                  <option
                    v-for="option in ['accountTransfer', 'bankingFee', 'debit', 'transaction', 'wrongAccount']"
                    :value="option"
                    :key="option"
                  >
                    {{ option | startCase }}
                  </option>
                </select></span
              >
            </small>
          </h1>
          <p class="transaction-edit-layer__purpose">
            <span v-for="(part, i) in transaction.parsedPurpose" :key="i">
              <button
                v-if="part.type === 'invoiceCollectionId'"
                class="transaction-edit-layer__select-match-button"
                @click="onInvoiceCollectionClick(part.invoiceCollectionId)"
              >
                {{ part.string }}
              </button>
              <button
                v-else-if="part.type === 'invoiceId'"
                class="transaction-edit-layer__select-match-button"
                @click="onInvoiceClick(part.invoiceId)"
              >
                {{ part.string }}
              </button>
              <button
                v-else-if="part.type === 'creditNote'"
                class="transaction-edit-layer__select-match-button"
                @click="loadCreditNote(part.creditNoteId)"
              >
                {{ part.string }}
              </button>
              <button
                v-else-if="part.type === 'sepaFileId'"
                class="transaction-edit-layer__select-match-button"
                @click="onSepaFileClick(part.sepaFileId)"
              >
                {{ part.string }}
              </button>
              <span v-else>{{ part.string }}</span>
            </span>
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <form
            class="transaction-edit-layer__add-invoice-form d-flex flex-shrink-0"
            @submit.prevent="loadInvoiceCollection(`S-${addInvoiceInput}`), (addInvoiceInput = '')"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">S-</span>
              </div>
              <input type="text" class="form-control" v-model="addInvoiceInput" />
            </div>
          </form>
        </div>
        <p class="h5 text-right mb-0">
          {{ formatCurrency(transaction.amount) }}
          <span
            :class="{
              'text-success': assignmentsSum === transaction.amount,
              'text-danger': assignmentsSum > transaction.amount
            }"
          >
            ({{ formatCurrency(transaction.amount - assignmentsSum) }})</span
          >
        </p>
      </div>
      <hr />
      <div v-if="Object.keys(invoiceCollections).length !== 0 || Object.keys(creditNotes).length !== 0">
        <div v-for="invoiceCollection in invoiceCollections" :key="invoiceCollection.id">
          <TransactionInvoiceTable
            :invoiceCollection="invoiceCollection"
            :assignments="assignments.filter(assignment => assignment.invoiceCollectionId === invoiceCollection.id)"
            :transactionId="transaction.id"
            @setAssignment="setAssignment"
            @removeAssignment="removeAssignment"
          />
        </div>
        <table class="table table-sm" v-if="creditNotes && creditNotes.length >= 1">
          <thead class="thead-dark">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Invoice</th>
              <th scope="col">Name</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="creditNote in creditNotes" :key="creditNote.id">
              <td>{{ creditNote.id }}</td>
              <td>{{ creditNote.invoiceId.replace('_', '/') }}</td>
              <td>{{ creditNote.address.name }}</td>
              <td>{{ formatCurrency(creditNote.sums.gross.total) }}</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
      <div class="mt-4 mb-4" v-else>Nothing assignments loaded...</div>
      <hr />
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="custom-control-inline custom-switch">
          <input
            type="checkbox"
            class="custom-control-input success"
            id="problematic"
            :checked="transaction.problematic"
            @change="toggleProblematic"
          />
          <label class="custom-control-label" for="problematic">Problematic</label>
        </div>

        <p class="h5 text-right mb-0">
          {{ formatCurrency(transaction.amount) }}
          <span
            :class="{
              'text-success': assignmentsSum.toFixed(2) === transaction.amount.toFixed(2),
              'text-danger': assignmentsSum > parseFloat(transaction.amount.toFixed(2))
            }"
          >
            ({{ formatCurrency(transaction.amount - assignmentsSum) }})</span
          >
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-grow-1">
          <input type="text" class="form-control flex-grow-1 mr-5" placeholder="Note" v-model="note" />
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <button
            class="btn ml-4"
            :class="{
              'btn-outline-secondary': assignmentsSum < transaction.amount,
              'btn-success': assignmentsSum === transaction.amount,
              'btn-danger': assignmentsSum > transaction.amount
            }"
            @click="save"
            :disabled="isSaving || assignmentsSum > transaction.amount"
          >
            {{ !isSaving ? 'Save' : 'Saving...' }}
          </button>
        </div>
      </div>
    </div>
  </Layer>
</template>

<script>
import Layer from '@/shared/Layer'
import TransactionInvoiceTable from './TransactionInvoiceTable'
import { db, fb } from '@/shared/firebase'
import UpdateTransactionsInInvoices from '@/helpers/transactions/UpdateTransactionsInInvoices'

export default {
  components: { Layer, TransactionInvoiceTable },
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      addInvoiceInput: '',
      addCreditNoteInput: '',
      invoiceCollections: {},
      creditNotes: {},
      expectedPayments: [],
      assignments: [],
      isSaving: false,
      note: ''
    }
  },
  created() {
    if (this.transaction.assignments) {
      this.assignments = JSON.parse(JSON.stringify(this.transaction.assignments))
      const invoiceCollectionIds = [...new Set(this.assignments.map(assignment => assignment.invoiceCollectionId))]
      invoiceCollectionIds.forEach(invoiceCollectionId => {
        this.loadInvoiceCollection(invoiceCollectionId)
      })
    }
    if (this.transaction.note) this.note = this.transaction.note
  },
  computed: {
    assignmentsSum() {
      if (this.assignments.length === 0) return 0
      return parseFloat(
        this.assignments
          .map(assignment => {
            return assignment.amount
          })
          .reduce((a, b) => a + b)
          .toFixed(2)
      )
    }
  },
  methods: {
    onInvoiceCollectionClick(invoiceCollectionId) {
      this.loadInvoiceCollection(invoiceCollectionId, true)
    },
    onInvoiceClick(invoiceId) {
      const invoiceCollectionId = invoiceId.split('_')[0]
      this.expectedPayments.push({
        invoiceCollectionId,
        invoiceId
      })
      this.loadInvoiceCollection(invoiceCollectionId)
    },
    async onSepaFileClick(sepaFileId) {
      const sepaFile = await db
        .doc(`private/banking/sepaFiles/${sepaFileId}`)
        .get()
        .then(doc => {
          return { ...doc.data(), id: doc.id }
        })
      const payments = sepaFile.sepaPayments.map(payment => {
        const matchedInvoiceCollectionId = payment.purpose.match(/S-[0-9]+/g)
        const matchedInvoiceId = payment.purpose.match(/S-[0-9]+\/[0-9]+/g)
        const paymentObject = {
          amount: payment.amount,
          invoiceCollectionId: matchedInvoiceCollectionId ? matchedInvoiceCollectionId[0] : null,
          invoiceId: matchedInvoiceId ? matchedInvoiceId[0].replace('/', '_') : null
        }
        this.expectedPayments.push(paymentObject)
        return paymentObject
      })
      const invoiceCollectionIds = [...new Set(payments.map(payment => payment.invoiceCollectionId))]
      invoiceCollectionIds.forEach(invoiceCollectionId => this.loadInvoiceCollection(invoiceCollectionId))
    },
    async loadInvoiceCollection(invoiceCollectionId, assignAllInvoices) {
      const invoiceCollection = await db
        .doc(`invoiceCollections/${invoiceCollectionId}`)
        .get()
        .then(doc => {
          return { ...doc.data(), id: doc.id }
        })
      const invoices = await db
        .collection('invoices')
        .where('invoiceCollectionId', '==', invoiceCollectionId)
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      if (assignAllInvoices) {
        invoices.forEach(invoice => {
          this.expectedPayments.push({
            invoiceCollectionId,
            invoiceId: invoice.id,
            amount: invoice.sums.gross.total
          })
        })
      }
      const sum = invoices.map(invoice => invoice.sums.gross.total).reduce((a, b) => a + b)

      this.$set(this.invoiceCollections, [invoiceCollectionId], {
        ...invoiceCollection,
        invoices,
        sum
      })
      this.expectedPayments
        .filter(p => p.invoiceCollectionId === invoiceCollectionId)
        .forEach(expectedPayment => {
          if (expectedPayment.invoiceId) {
            if (!expectedPayment.amount) {
              expectedPayment.amount = invoices.find(i => i.id === expectedPayment.invoiceId).sums.gross.total
            }
            this.setAssignment({
              ...expectedPayment,
              createdAt: new Date()
            })
          } else {
            const invoiceCollection = this.invoiceCollections[expectedPayment.invoiceCollectionId]
            if (!invoiceCollection) {
              return console.warn(
                `didn't find invoiceCollection ${expectedPayment.invoiceCollectionId} from expectedPayments`
              )
            }
            invoiceCollection.invoices.forEach(invoice => {
              this.setAssignment({
                invoiceCollectionId: invoice.invoiceCollectionId,
                invoiceId: invoice.id,
                amount: invoice.sums.gross.total,
                createdAt: new Date()
              })
            })
          }
        })
    },
    async loadCreditNote(creditNoteId) {
      const creditNote = await this.$get(`creditNotes/${creditNoteId}`)
      this.$set(this.creditNotes, [creditNoteId], creditNote)
    },
    setAssignment(assignment) {
      const index = this.assignments.findIndex(a => a.invoiceId === assignment.invoiceId)
      assignment.createdAt = new Date()
      if (index !== -1) {
        this.$set(this.assignments, [index], assignment)
      } else {
        this.assignments.push(assignment)
      }
    },
    removeAssignment(assignment) {
      const index = this.assignments.findIndex(a => a.invoiceId === assignment.invoiceId)
      if (index !== -1) this.assignments.splice(index, 1)
    },
    async save() {
      this.isSaving = true

      try {
        const oldAssignments = _.cloneDeep(this.transaction.assignments) || []
        const newAssignments = _.cloneDeep(this.assignments)

        const customerIds = {}

        for (const a of newAssignments) {
          if (a.invoiceCollectionId) {
            if (!this.invoiceCollections[a.invoiceCollectionId]) {
              return alert(`invoiceCollection ${a.invoiceCollectionId} not found`)
            }
            const { organizationId } = this.invoiceCollections[a.invoiceCollectionId]
            if (!customerIds[organizationId]) {
              customerIds[organizationId] = await db
                .doc(`organizations/${organizationId}`)
                .get()
                .then(doc => {
                  return doc.data().customerId
                })
            }
            a.customerId = customerIds[organizationId]
          }
        }

        const assignedAmount = this.assignmentsSum
        const transactionAmount = this.transaction.amount

        db.doc(`private/banking/transactions/${this.transaction.id}`).update({
          assigned: assignedAmount === transactionAmount,
          assignments: newAssignments.length === 0 ? fb.firestore.FieldValue.delete() : newAssignments,
          assignedAmount,
          note: this.note || fb.firestore.FieldValue.delete()
        })

        await UpdateTransactionsInInvoices(oldAssignments, newAssignments, this.transaction)

        this.isSaving = false
        this.$emit('close')
      } catch (error) {
        this.isSaving = false
        console.warn(error)
        alert(error.message)
      }
    },
    toggleProblematic() {
      db.doc(`private/banking/transactions/${this.transaction.id}`).update({
        problematic: !this.transaction.problematic
      })
    },
    changeTransactionType(e) {
      const type = e.target.value
      const forceAssigned = ['accountTransfer', 'bankingFee', 'wrongAccount'].includes(type)
      const assigned = forceAssigned || this.assignmentsSum === this.transaction.amount
      let confirmText = `Are you sure you want to change the assignment type to ${_.startCase(type)}`
      if (forceAssigned) confirmText += ' and set the status to assigned'
      const confirmed = confirm(`${confirmText}?`)
      if (confirmed) {
        db.doc(`private/banking/transactions/${this.transaction.id}`).update({ assigned, type })
      }
    }
  },
  filters: {
    startCase(str) {
      return _.startCase(str)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.transaction-edit-layer {
  padding: 1rem 2rem 2rem;

  &__name {
    font-size: 1.375rem;
    margin: 0 0 0.75rem;

    small {
      display: block;
      margin: 0.375rem 0 0;

      font-family: $font-family-mono;
      font-size: 0.75rem;
    }
  }

  &__purpose {
    font-family: $font-family-mono;
  }

  &__select-match-button {
    @extend %cleanbutton;

    background: #bcd8b2;
    border-radius: 3px;
    padding: 0 4px;
  }

  &__invoice-collection {
    &__title {
      font-size: 1rem;
    }
  }

  &__add-invoice-form {
    width: 7rem;
  }
}
</style>
