var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-hover table-sm transaction-invoice-table"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"align-middle text-center",attrs:{"width":"25"}},[_c('input',{attrs:{"type":"checkbox","id":_vm.invoiceCollection.id},domProps:{"checked":_vm.allInvoicesAreAssigned},on:{"change":_vm.onInvoiceCollectionCheck}})]),_c('th',{staticClass:"align-middle"},[_c('label',{attrs:{"for":_vm.invoiceCollection.id}},[_vm._v(_vm._s(_vm.invoiceCollection.id))])]),_c('th',[_c('label',{attrs:{"for":_vm.invoiceCollection.id}},[_vm._v(_vm._s(_vm.invoiceCollection.address.name))])]),_c('th',{staticClass:"align-middle"},[_c('label',{attrs:{"for":_vm.invoiceCollection.id}},[_vm._v("Assign")])]),_c('th',{staticClass:"align-middle text-right"},[_c('label',{attrs:{"for":_vm.invoiceCollection.id}},[_vm._v("Assigned")])])])]),_c('tbody',{staticClass:"align-middle"},_vm._l((_vm.invoiceCollection.invoices),function(invoice){return _c('tr',{key:invoice.id,staticClass:"align-middle",class:{
        'table-success':
          _vm.getAssignment(invoice.id) &&
          _vm.getAssignment(invoice.id).amount.toFixed(2) === invoice.sums.gross.total.toFixed(2),
        'table-warning':
          _vm.getAssignment(invoice.id) &&
          _vm.getAssignment(invoice.id).amount.toFixed(2) !== invoice.sums.gross.total.toFixed(2)
      }},[_c('td',{staticClass:"align-middle text-center",attrs:{"width":"25"}},[_c('input',{attrs:{"type":"checkbox","id":((_vm.invoiceCollection.id) + "/" + (invoice.id))},domProps:{"checked":_vm.getAssignment(invoice.id)},on:{"change":function (e) { return _vm.onInvoiceCheck(("" + (invoice.id)), invoice.sums.gross.total, e); }}}),_vm._v(" "+_vm._s(_vm.assignments[((_vm.invoiceCollection.id) + "/" + (invoice.id))])+" ")]),_c('td',{staticClass:"align-middle",attrs:{"width":"140"}},[_c('label',{attrs:{"for":((_vm.invoiceCollection.id) + "/" + (invoice.id))}},[_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.invoiceCollection.id)+"/")]),_vm._v(_vm._s(invoice.id.split('_')[1])+" ")])]),_c('td',{staticClass:"align-middle"},[_c('label',{attrs:{"for":((_vm.invoiceCollection.id) + "/" + (invoice.id))}},[_vm._v(_vm._s(invoice.address.name1)+" "),(invoice.payment.method === 'sepa')?_c('span',{staticClass:"badge badge-primary badge-pill"},[_vm._v(" S ")]):_vm._e()])]),_c('td',{staticClass:"text-right",attrs:{"width":"100"}},[_c('currency-input',{staticClass:"transaction-invoice-table__currency-input",attrs:{"value":_vm.getAssignment(invoice.id) ? _vm.getAssignment(invoice.id).amount : 0,"currency":"EUR","locale":"de","placeholder":"0,00 €"},on:{"change":function (amount) { return _vm.$emit('setAssignment', {
                invoiceCollectionId: _vm.invoiceCollection.id,
                invoiceId: invoice.id,
                amount: amount
              }); }}})],1),_c('td',{staticClass:"text-right align-middle",attrs:{"width":"200","set":(_vm.assignedTotal = _vm.getAssignedTotal(invoice.id))}},[_c('span',{staticClass:"badge badge-pill badge-secondary",class:{
            'badge-success': _vm.assignedTotal === parseFloat(invoice.sums.gross.total.toFixed(2)),
            'badge-danger': _vm.assignedTotal > parseFloat(invoice.sums.gross.total.toFixed(2))
          },attrs:{"for":((_vm.invoiceCollection.id) + "/" + (invoice.id))}},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.assignedTotal))+" ")]),_c('span',[_vm._v(" / ")]),_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v(_vm._s(_vm.formatCurrency(invoice.sums.gross.total)))]),_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v(_vm._s(invoice.transactions ? invoice.transactions.length : 0))])])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"4"}},[_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(_vm.invoiceCollection.sum)))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(_vm.assignedSum)))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }