<template>
  <div>
    <div>
      <label for="file-upload" class="btn btn-outline-primary mb-0">Import</label>
      <input id="file-upload" type="file" class="d-none" @click="clearRef" @change="parseCSV" ref="fileInput" />
    </div>
    <Layer v-if="file" @close="file = null" title="Import transactions" width="60rem" padding="1rem">
      <ul v-if="true" class="list-group">
        <li v-for="(transaction, i) in transactions" :key="i" class="list-group-item">
          <strong class="d-block">{{ i + 1 }}. {{ transaction['Name Zahlungsbeteiligter'] }}</strong>
          <span>{{ transaction['Purpose'].purpose }}</span>
          <small class="d-block mt-1">
            <span>{{ transaction['Buchungstag'] }}</span>
            <span class="ml-2">{{ transaction['Betrag'] }} €</span>
            <span class="ml-2"
              >{{ transaction['IBAN Zahlungsbeteiligter'] }} ({{
                transaction['BIC (SWIFT-Code) Zahlungsbeteiligter']
              }})</span
            >
          </small>
        </li>
      </ul>

      <div class="d-flex justify-content-end p-3">
        <button class="btn btn-primary" @click="uploadData" :disabled="pending">
          {{ pending ? 'Importing...' : `Import ${transactions.length} Transactions` }}
        </button>
      </div>
    </Layer>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'

import Layer from '@/components/Layer'
import Papa from 'papaparse'
import multiBatch from '@/shared/multiBatch'

import moment from 'moment'
require('moment/locale/de')

export default {
  props: {
    bucket: {
      type: String
    },
    rootPath: {
      type: String,
      default: '/'
    }
  },

  components: { Layer },
  data() {
    return {
      showFilePreview: null,
      file: null,
      pending: null,
      uploaded: null,
      transactions: []
    }
  },

  methods: {
    async parseCSV() {
      this.transactions = []
      this.file = this.$refs.fileInput.files[0]

      Papa.parsePromise = file => {
        return new Promise((complete, error) => {
          Papa.parse(file, { header: true, encoding: 'UTF-8', skipEmptyLines: true, complete, error })
        })
      }

      return await Papa.parsePromise(this.file).then(results => {
        results.data.forEach(data => {
          const purpose = this.getDataFromString(data['Buchungstext'], data['Verwendungszweck'])
          if (purpose.type === 'bankingFee') {
            data['Name Zahlungsbeteiligter'] = purpose.purpose
          }
          this.transactions.push({ ...data, ['Purpose']: purpose })
        })
      })
    },

    getDataFromString(transactionType, purpose) {
      const data = { purpose }
      const keysToSearch = {
        ['EREF: ']: 'reference',
        ['Transaction Type: ']: 'type',
        ['Mandate: ']: 'mandate',
        ['Creditor ID: ']: 'creditorId'
      }
      const transactionTypes = {
        ['Lastschrift']: 'debit',
        ['Überweisungsgutschr.']: 'transaction',
        ['Echtzeitüberweisung']: 'transaction',
        ['Umbuchung']: 'accountTransfer',
        ['Abschluss']: 'bankingFee',
        ['Mehrwertsteuerbelast']: 'bankingFee'
      }
      Object.keys(transactionTypes).forEach(type => {
        const index = transactionType.indexOf(type)
        if (index !== -1) {
          data.type = transactionTypes[type]
        }
      })

      Object.keys(keysToSearch).forEach(key => {
        const index = purpose.indexOf(key)
        if (index !== -1) {
          const value = purpose.substr(purpose.indexOf(key) + key.length).split(' ')[0]
          data[keysToSearch[key]] = value.split(',')[0]
        }
      })

      data.purpose = purpose

      return data
    },

    async uploadData(event) {
      event.preventDefault()
      this.pending = true

      try {
        await this.parseCSV()
        const batch = new multiBatch()

        const importedAt = new Date()

        this.transactions.forEach(result => {
          const transactionsRef = db.collection('private/banking/transactions').doc()

          const data = {
            ...result['Purpose'],
            amount: parseFloat(result['Betrag'].replace(/,/g, '.')),
            bic: result['BIC (SWIFT-Code) Zahlungsbeteiligter'],
            date: moment(result['Buchungstag'], 'DD.MM.YYYY').format('YYYY-MM-DD'),
            iban: result['IBAN Zahlungsbeteiligter'],
            importedAt,
            name: result['Name Zahlungsbeteiligter'],
            valueDate: moment(result['Valutadatum'], 'DD.MM.YYYY').format('YYYY-MM-DD')
          }

          if (data.type === 'accountTransfer' || data.type === 'bankingFee') {
            data.assigned = true
            data.assignedAmount = data.amount
          }

          batch.set(transactionsRef, data)
        })

        batch.commit().then(() => {
          this.pending = false
          this.showFilePreview = false
          this.file = null
        })
      } catch (err) {
        alert(`Oops! Some error occurred. \nError message: ${err.message}`)
        this.pending = false
        this.file = null

        console.error(`ERROR: ${err}`)
      }
    },

    clearRef() {
      this.$refs.fileInput.value = null
    },

    onChange() {
      this.file = this.$refs.fileInput.files[0]

      this.pending = false
      this.uploaded = false
    }
  }
}
</script>
