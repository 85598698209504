import CompareAssignments from './CompareAssignments'
import GetInvoices from './GetInvoices'
import UpdateOrderPaidStatus from './UpdateOrderPaidStatus'
import { db, fb } from '@/shared/firebase'

const UpdateTransactionsInInvoices = async (oldAssignments, newAssignments, transaction) => {
  const { updatedAssignments, deletedAssignments, changedInvoiceIds, changedInvoiceCollectionIds } = CompareAssignments(
    oldAssignments,
    newAssignments
  )

  const { invoices } = await GetInvoices(changedInvoiceCollectionIds)

  const invoiceBatch = db.batch()
  changedInvoiceIds.forEach(invoiceId => {
    const invoice = invoices.find(s => s.id === invoiceId)
    if (!invoice.transactions) invoice.transactions = []

    const updatedAssignment = updatedAssignments.find(a => a.invoiceId === invoiceId)
    if (updatedAssignment) {
      const newTransaction = {
        amount: updatedAssignment.amount,
        assignedAt: new Date(),
        date: transaction.date,
        transactionId: transaction.id,
        type: 'deposit'
      }

      const transactionIndex = invoice.transactions.findIndex(t => t.transactionId === transaction.id)
      if (transactionIndex !== -1) {
        invoice.transactions[transactionIndex] = newTransaction
      } else {
        invoice.transactions.push(newTransaction)
      }
    }

    const deletedAssignment = deletedAssignments.find(a => a.invoiceId === invoiceId)
    if (deletedAssignment) {
      const transactionIndex = invoice.transactions.findIndex(t => t.transactionId === transaction.id)
      if (transactionIndex !== -1) {
        invoice.transactions.splice(transactionIndex, 1)
      }
    }

    const invoiceRef = db.doc(`invoices/${invoiceId}`)
    if (invoice.transactions.length !== 0) {
      const paidAmount = parseFloat(
        invoice.transactions
          .map(transaction => {
            return transaction.amount
          })
          .reduce((a, b) => a + b)
          .toFixed(2)
      )
      const invoiceSum = parseFloat(invoice.sums.gross.total.toFixed(2))
      invoiceBatch.update(invoiceRef, {
        completelyPaid: paidAmount === invoiceSum,
        paidAmount,
        transactions: invoice.transactions
      })
    } else {
      invoiceBatch.update(invoiceRef, {
        completelyPaid: fb.firestore.FieldValue.delete(),
        paidAmount: fb.firestore.FieldValue.delete(),
        transactions: fb.firestore.FieldValue.delete()
      })
    }
  })
  await invoiceBatch.commit()

  for (const invoiceCollectionId of changedInvoiceCollectionIds) {
    await UpdateOrderPaidStatus(invoiceCollectionId)
  }
}

export default UpdateTransactionsInInvoices
