<template>
  <div>
    <button class="btn btn-outline-primary" @click="showAutoAssignLayer">Auto Assign</button>
    <Layer v-if="showLayer" title="Auto Assign" padding="3rem 2rem" @close="hideAutoAssignLayer">
      <div v-if="status === 'idle'" class="text-center">
        <h1 class="h5">{{ transactions.length }} transactions selected</h1>
        <button class="btn btn-outline-success mt-2" @click="startAssignment">Start Assignment</button>
      </div>
      <div v-if="['processing', 'finished'].includes(status)">
        <p class="text-center font-weight-bold">
          {{ status === 'processing' ? 'Assignment in progress...' : 'Finished!' }}
        </p>
        <div class="progress mb-2">
          <div
            class="progress-bar bg-success"
            :style="`width: ${(progress.success / selectedTransactions.length) * 100}%`"
          >
            {{ progress.success }}
          </div>
          <div
            class="progress-bar bg-warning"
            :style="`width: ${(progress.failed / selectedTransactions.length) * 100}%`"
          >
            {{ progress.failed }}
          </div>
        </div>
        <div class="d-block text-center">
          {{ progress.success + progress.failed }} / {{ selectedTransactions.length }}
        </div>
      </div>
    </Layer>
  </div>
</template>

<script>
import Layer from '@/components/Layer'
import { db } from '@/shared/firebase'
import UpdateTransactionsInInvoices from '@/helpers/transactions/UpdateTransactionsInInvoices'

export default {
  props: {
    transactions: {
      validator: prop => Array.isArray(prop) || prop === null,
      required: true
    }
  },
  components: { Layer },
  data() {
    return {
      selectedTransactions: null,
      showLayer: false,
      status: 'idle',
      transactionIndex: 0,
      organizationIdByInvoiceCollectionId: {},
      customerIds: {},
      progress: {
        success: 0,
        failed: 0
      }
    }
  },
  methods: {
    showAutoAssignLayer() {
      this.status = 'idle'
      this.showLayer = true
      this.stopped = false
    },
    hideAutoAssignLayer() {
      this.showLayer = false
      this.stopped = true
    },
    startAssignment() {
      const transactions = this.transactions.map(transaction => {
        return { ...transaction, id: transaction.id }
      })
      this.selectedTransactions = transactions
      this.transactionIndex = 0
      this.progress = {
        success: 0,
        failed: 0
      }
      this.status = 'processing'
      this.triggerNextAnalysis()
    },
    async triggerNextAnalysis() {
      const success = await this.analyzeTransaction(this.selectedTransactions[this.transactionIndex])
      this.progress[success ? 'success' : 'failed']++
      this.transactionIndex++
      if (this.transactionIndex < this.selectedTransactions.length && !this.stopped) return this.triggerNextAnalysis()
      return (this.status = 'finished')
    },
    async analyzeTransaction(transaction) {
      try {
        const assignments = []
        const invoices = {}
        const promises = []

        transaction.parsedPurpose
          .filter(p => p.type === 'invoiceId')
          .forEach(p => {
            promises.push(
              db
                .doc(`invoices/${p.invoiceId}`)
                .get()
                .then(doc => (invoices[doc.id] = doc.data()))
            )
            return p.invoiceId
          })

        transaction.parsedPurpose
          .filter(p => p.type === 'invoiceCollectionId')
          .forEach(p => {
            promises.push(
              db
                .collection('invoices')
                .where('invoiceCollectionId', '==', p.invoiceCollectionId)
                .get()
                .then(snapshot => {
                  snapshot.docs.map(doc => (invoices[doc.id] = doc.data()))
                })
            )
            return p.invoiceCollectionId
          })

        await Promise.all(promises)

        let assignedAmount = 0
        let hasTransactions = false

        Object.keys(invoices).forEach(invoiceId => {
          const invoice = invoices[invoiceId]
          const amount = parseFloat(invoice.sums.gross.total.toFixed(2))
          assignments.push({
            amount,
            createdAt: new Date(),
            invoiceId,
            invoiceCollectionId: invoice.invoiceCollectionId
          })
          if (invoice.transactions && invoice.transactions.length !== 0) hasTransactions = true
          assignedAmount += amount
        })

        const sameAmount = parseFloat(assignedAmount.toFixed(2)) === transaction.amount

        if (sameAmount && !hasTransactions) {
          for (const a of assignments) {
            if (a.invoiceCollectionId) {
              if (!this.organizationIdByInvoiceCollectionId[a.invoiceCollectionId]) {
                this.organizationIdByInvoiceCollectionId[a.invoiceCollectionId] = await db
                  .doc(`invoiceCollections/${a.invoiceCollectionId}`)
                  .get()
                  .then(doc => doc.data().organizationId)
              }

              const organizationId = this.organizationIdByInvoiceCollectionId[a.invoiceCollectionId]

              if (!this.customerIds[organizationId]) {
                this.customerIds[organizationId] = await db
                  .doc(`organizations/${organizationId}`)
                  .get()
                  .then(doc => {
                    return doc.data().customerId
                  })
              }
              a.customerId = this.customerIds[organizationId]
            }
          }

          await db.doc(`private/banking/transactions/${transaction.id}`).update({
            assigned: true,
            assignedAmount,
            assignments
          })

          await UpdateTransactionsInInvoices([], assignments, transaction)

          console.info('auto assigned', transaction.id, transaction)

          return true
        } else {
          console.warn(`couldn't auto assign ${transaction.id}`, {
            transaction,
            sameAmount,
            hasTransactions,
            transactionAmount: transaction.amount,
            assignedAmount
          })
          return false
        }
      } catch (err) {
        console.warn(err.message, transaction.id, { transaction })
        return false
      }
    }
  }
}
</script>
