<template>
  <table class="table table-hover table-sm transaction-invoice-table">
    <thead class="thead-dark">
      <tr>
        <th width="25" class="align-middle text-center">
          <input
            type="checkbox"
            :checked="allInvoicesAreAssigned"
            @change="onInvoiceCollectionCheck"
            :id="invoiceCollection.id"
          />
        </th>
        <th class="align-middle">
          <label :for="invoiceCollection.id">{{ invoiceCollection.id }}</label>
        </th>
        <th>
          <label :for="invoiceCollection.id">{{ invoiceCollection.address.name }}</label>
        </th>
        <th class="align-middle"><label :for="invoiceCollection.id">Assign</label></th>
        <th class="align-middle text-right"><label :for="invoiceCollection.id">Assigned</label></th>
      </tr>
    </thead>
    <tbody class="align-middle">
      <tr
        v-for="invoice in invoiceCollection.invoices"
        :key="invoice.id"
        class="align-middle"
        :class="{
          'table-success':
            getAssignment(invoice.id) &&
            getAssignment(invoice.id).amount.toFixed(2) === invoice.sums.gross.total.toFixed(2),
          'table-warning':
            getAssignment(invoice.id) &&
            getAssignment(invoice.id).amount.toFixed(2) !== invoice.sums.gross.total.toFixed(2)
        }"
      >
        <td width="25" class="align-middle text-center">
          <input
            type="checkbox"
            :id="`${invoiceCollection.id}/${invoice.id}`"
            @change="e => onInvoiceCheck(`${invoice.id}`, invoice.sums.gross.total, e)"
            :checked="getAssignment(invoice.id)"
          />
          {{ assignments[`${invoiceCollection.id}/${invoice.id}`] }}
        </td>
        <td width="140" class="align-middle">
          <label :for="`${invoiceCollection.id}/${invoice.id}`">
            <small class="text-secondary">{{ invoiceCollection.id }}/</small>{{ invoice.id.split('_')[1] }}
          </label>
        </td>
        <td class="align-middle">
          <label :for="`${invoiceCollection.id}/${invoice.id}`"
            >{{ invoice.address.name1 }}
            <span class="badge badge-primary badge-pill" v-if="invoice.payment.method === 'sepa'">
              S
            </span></label
          >
        </td>
        <td width="100" class="text-right">
          <currency-input
            :value="getAssignment(invoice.id) ? getAssignment(invoice.id).amount : 0"
            @change="
              amount =>
                $emit('setAssignment', {
                  invoiceCollectionId: invoiceCollection.id,
                  invoiceId: invoice.id,
                  amount
                })
            "
            currency="EUR"
            locale="de"
            placeholder="0,00 €"
            class="transaction-invoice-table__currency-input"
          />
        </td>
        <td width="200" class="text-right align-middle" :set="(assignedTotal = getAssignedTotal(invoice.id))">
          <span
            :for="`${invoiceCollection.id}/${invoice.id}`"
            class="badge badge-pill badge-secondary"
            :class="{
              'badge-success': assignedTotal === parseFloat(invoice.sums.gross.total.toFixed(2)),
              'badge-danger': assignedTotal > parseFloat(invoice.sums.gross.total.toFixed(2))
            }"
          >
            {{ formatCurrency(assignedTotal) }}
          </span>
          <span> / </span>
          <span class="badge badge-pill badge-secondary">{{ formatCurrency(invoice.sums.gross.total) }}</span>
          <span class="badge badge-pill badge-secondary">{{
            invoice.transactions ? invoice.transactions.length : 0
          }}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="4" class="text-right">
          <strong>{{ formatCurrency(invoiceCollection.sum) }}</strong>
        </td>
        <td class="text-right">
          <strong>{{ formatCurrency(assignedSum) }}</strong>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: {
    invoiceCollection: {
      type: Object,
      required: true
    },
    assignments: {
      type: Array,
      required: true,
      default: () => []
    },
    transactionId: {
      type: String,
      required: true
    }
  },
  computed: {
    allInvoicesAreAssigned() {
      let allAssigned = true
      this.invoiceCollection.invoices.forEach(invoice => {
        const assignment = this.assignments.find(assignment => assignment.invoiceId === invoice.id)
        if (!assignment) allAssigned = false
      })
      return allAssigned
    },
    assignedSum() {
      let sum = 0
      this.assignments.forEach(assignment => {
        sum += assignment.amount
      })
      return sum
    }
  },
  methods: {
    getAssignment(invoiceId) {
      return this.assignments.find(assignment => assignment.invoiceId === invoiceId)
    },
    getAssignedTotal(invoiceId) {
      let total = 0
      const assignment = this.getAssignment(invoiceId)
      const invoice = this.invoiceCollection.invoices.find(i => i.id === invoiceId)
      if (invoice.transactions) {
        invoice.transactions.filter(t => t.transactionId !== this.transactionId).forEach(t => (total += t.amount))
      }
      if (assignment) total += assignment.amount
      return parseFloat(total.toFixed(2))
    },
    onInvoiceCollectionCheck() {
      const { allInvoicesAreAssigned, assignments, invoiceCollection } = this
      if (allInvoicesAreAssigned) {
        assignments.forEach(assignment => {
          this.$emit('removeAssignment', { ...assignment })
        })
      } else {
        invoiceCollection.invoices.forEach(invoice => {
          if (!this.getAssignment(invoice.id)) {
            this.$emit('setAssignment', {
              invoiceCollectionId: this.invoiceCollection.id,
              invoiceId: invoice.id,
              amount: invoice.sums.gross.total
            })
          }
        })
      }
    },
    onInvoiceCheck(invoiceId, amount, e) {
      if (e.target.checked) {
        this.$emit('setAssignment', {
          invoiceCollectionId: this.invoiceCollection.id,
          invoiceId,
          amount: parseFloat(amount.toFixed(2))
        })
      } else {
        this.$emit('removeAssignment', {
          invoiceCollectionId: this.invoiceCollection.id,
          invoiceId
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction-invoice-table {
  width: 100%;

  label {
    margin-bottom: 0;
    display: block;
  }

  &__currency-input {
    width: 80px;
    text-align: right;
    border: 1px #ddd solid;
    outline: none;
  }
}
</style>
