<template>
  <div>
    <button class="btn btn-outline-primary" @click="exportTransactions">Export</button>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'

export default {
  props: {
    transactions: {
      validator: prop => Array.isArray(prop) || prop === null,
      required: true
    },
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    exportTransactions() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Transactions')
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'Umsatz', key: 'revenue', width: 10 },
        { header: 'Soll/Haben-Kennzeichen', key: 'debitCredit' },
        { header: 'WKZ Umsatz', key: 'WKZRevenue' },
        { header: 'Kurs', key: 'rate' },
        { header: 'Basis-Umsatz', key: 'basicRevenue' },
        { header: 'WKZ Basis-Umsatz', key: 'WKZBasicRevenue' },
        { header: 'Konto', key: 'account' },
        { header: 'Gegenkonto (ohne BU-Schlüssel) ', key: 'contraAccount' },
        { header: 'BU-Schlüssel', key: 'BU' },
        { header: 'Belegdatum', key: 'invoiceDate', width: 10 },
        { header: 'Belegfeld 1', key: 'invoiceField1', width: 14 },
        { header: 'Belegfeld 2', key: 'invoiceField2' },
        { header: 'Skonto', key: 'discount' },
        { header: 'Buchungstext', key: 'bookingText', width: 50 },
        { header: 'Transaktions ID', key: 'id', width: 25 }
      ]

      this.transactions.forEach(t => {
        const booking = {
          debitCredit: 'S',
          account: '1210',
          invoiceDate: moment(t.date).format('DMM'),
          bookingText: t.purpose,
          id: t.id
        }
        let notAssignedAmount = t.amount
        if (['accountTransfer', 'bankingFee', 'wrongAccount'].includes(t.type)) {
          if (t.assignments) return alert(`Validation error: Transaction type ${type} can't have assignments.`)
          const contraAccounts = {
            accountTransfer: '1360',
            bankingFee: '4970',
            wrongAccount: '1591'
          }
          const row = {
            ...booking,
            revenue: t.amount,
            contraAccount: contraAccounts[t.type]
          }
          if (t.amount < 0) {
            row.debitCredit = 'H'
            row.revenue = Math.abs(t.amount)
          }
          worksheet.addRow(row)
          notAssignedAmount = 0
        } else if (t.assignments) {
          t.assignments.forEach(a => {
            notAssignedAmount = parseFloat((notAssignedAmount - a.amount).toFixed(2))
            let contraAccount = a.customerId
            const row = {
              ...booking,
              revenue: a.amount,
              contraAccount
            }
            if (a.invoiceId) row.invoiceField1 = a.invoiceId
            if (a.amount < 0) {
              row.debitCredit = 'H'
              row.revenue = Math.abs(a.amount)
            }
            worksheet.addRow(row)
          })
        }
        if (notAssignedAmount !== 0) {
          worksheet.addRow({
            ...booking,
            revenue: notAssignedAmount,
            contraAccount: '8400'
          })
        }
      })

      workbook.xlsx.writeBuffer().then(data => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let fileName = `Transactions ${this.from}`
        if (this.from !== this.to) fileName += ` - ${this.to}`
        fileName += '.xlsx'
        saveAs(blob, fileName)
      })
    }
  }
}
</script>
