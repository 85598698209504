<template>
  <TableSkeleton v-if="transactions === null" :small="true" />
  <table v-else class="table table-sm table-hover mb-5">
    <thead class="thead-light">
      <tr>
        <th scope="col" width="110">Date</th>
        <th scope="col">Sender / Purpose</th>
        <th scope="col" width="200" class="text-right">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="transaction in transactions"
        :key="transaction.id"
        @click="$emit('openAssignmentLayer', transaction.id)"
        style="cursor: pointer;"
        :class="{
          'table-success': transaction.assigned,
          'table-warning': transaction.problematic
        }"
      >
        <td class="align-middle">{{ transaction.date | date }}</td>
        <td class="align-middle" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;">
          <span class="badge badge-pill badge-warning mr-1" v-if="transaction.type === 'wrongAccount'">
            Wrong Account
          </span>
          <span class="badge badge-pill badge-success mr-1" v-if="transaction.type === 'accountTransfer'">
            Account Transfer
          </span>
          <span class="badge badge-pill badge-info mr-1" v-if="transaction.type === 'bankingFee'">
            Banking Fee
          </span>
          <strong>{{ transaction.name }}</strong>
          <small class="ml-2" style="color: #777; font-size: 0.9em;" v-if="transaction.parsedPurpose">
            <span v-if="transaction.note" :title="transaction.note" class="ml-1">🗒</span>
            <span v-for="(part, i) in transaction.parsedPurpose" :key="i">
              <span
                v-if="part.type !== 'text'"
                style="border-bottom: 1px #bbb solid; color: #000;"
                :title="part.invoiceCollectionId"
              >
                {{ part.string }}
              </span>
              <span v-else>{{ part.string }}</span>
            </span>
          </small>
        </td>
        <td class="align-middle text-right" style="white-space: nowrap;">
          <span
            v-if="
              parseFloat(transaction.amount.toFixed(2)) !== parseFloat((transaction.assignedAmount || 0).toFixed(2))
            "
          >
            {{ transaction.assignedAmount ? formatCurrency(transaction.assignedAmount) : '-' }} /&nbsp;
          </span>
          <span :class="{ 'text-success': transaction.amount >= 0, 'text-danger': transaction.amount < 0 }">{{
            formatCurrency(transaction.amount)
          }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TableSkeleton from '@/components/TableSkeleton'

export default {
  components: { TableSkeleton },
  props: {
    transactions: {
      validator: prop => Array.isArray(prop) || prop === null,
      required: true
    }
  },
  filters: {
    date(date) {
      return `${date.substr(8, 2)}.${date.substr(5, 2)}.${date.substr(0, 4)}`
    }
  }
}
</script>
