import { db } from '@/shared/firebase'

const GetInvoices = async invoiceCollectionIds => {
  const invoiceCollections = []
  const invoices = []

  const promises = []
  invoiceCollectionIds.forEach(invoiceCollectionId => {
    promises.push(
      db
        .doc(`invoiceCollections/${invoiceCollectionId}`)
        .get()
        .then(doc => {
          invoiceCollections.push({ ...doc.data(), id: doc.id })
        })
    )
    promises.push(
      db
        .collection('invoices')
        .where('invoiceCollectionId', '==', invoiceCollectionId)
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(doc => {
            invoices.push({ ...doc.data(), id: doc.id })
          })
        })
    )
  })

  await Promise.all(promises)

  return { invoiceCollections, invoices }
}

export default GetInvoices
